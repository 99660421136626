function initMemberSelects(){
  var member_field = $("#potential_ownership_member_id");
  member_field.select2({
    allowClear: true,
    placeholder: "Įrašykite vardą, pavardę",
    minimumInputLength: 2,
    multiple: false,
    width: '100%',
    language: lt,
    ajax: {
      quietMillis: 500,
      url: "/members.json?skip_create=1",
      dataType: "json",
      data: function(term, page) {
        return {
          q: { name: term.term },
          page: page
        };
      },
      processResults: function(data) {
        return { results: data.members }
      },
      results: function(data, page) {
        return {
          results: data.members,
          more: (page * 100) < data.total
        };
      }
    },
  });

  // Initialize default selection
  member_field.each(function(){
    var member_ids = $(this).data('selected');
    if(!!member_ids){
      fetchMemberSelections($(this), "/members.json?skip_create=1", [member_ids])
    }
  });

  function fetchMemberSelections(field, url, params){
    if(params.length > 0){
      var select_field = $(field);
      $.ajax({
        type: 'GET',
        url: url,
        data: {
          q: { members_id_in: params }
        }
      }).then(function (data) {
        data.members.map(function(el, index){
          var option = new Option(el.text, el.id, true, true);
          select_field.append(option).trigger('change');
        });
      });
    }
  }
}

function initTelevisionProgramSelects(){
  var tv_field = $('#potential_ownership_television_program_id');
  tv_field.select2({
    placeholder: 'Įrašykite pavadinimą',
    minimumInputLength: 2,
    multiple: false,
    width: '100%',
    language: lt,
    ajax: {
      url: "/television_programs.json",
      dataType: 'JSON',
      quietMillis: 300,
      data: function (term) {
        return {
          q: { id_in_or_name_cont: term.term },
        };
      },
      processResults: function(data) {
        return { results: data.television_programs }
      },
      results: function (data) {
        return { results: data.results };
      }
    },
  });
  
  // Initialize default selection
  tv_field.each(function(){
    var program_ids = $(this).data('selected');
    if(!!program_ids){
      fetchProgramSelections($(this), "/television_programs.json", [program_ids])
    }
  });

  function fetchProgramSelections(field, url, params){
    if(params.length > 0){
      var select_field = $(field);
      $.ajax({
        type: 'GET',
        url: url,
        data: {
          q: { id_in: params }
        }
      }).then(function (data) {
        data.television_programs.map(function(el, index){
          var option = new Option(el.text, el.id, true, true);
          select_field.append(option).trigger('change');
        });
      });
    }
  }
}

$(function() {
  initMemberSelects();
  initTelevisionProgramSelects();
});
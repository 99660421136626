$(function () {
  let defaultSelect2Opts = {
    allowClear: true,
    placeholder: 'Pasirinkite',
    width: '100%'
  };

  let message_group_options = $.extend(defaultSelect2Opts, {
    placeholder: "Įrašykite Gavėjų grupės pavadinimą",
    minimumInputLength: 2,
    multiple: false,
    language: lt,
    width: '100%',
    ajax: {
      url: "/message_groups.json",
      dataType: 'JSON',
      quietMillis: 500,
      data: function (term) {
        return {
          q: { 
            name: term.term,
          },
        };
      },
      processResults: function(data) {
        return { results: data }
      },
      results: function (data) {
        return { results: data.results };
      }
    },
  });

  $(document).find("[rel='message-groups']").each(function () {
    $(this).select2(message_group_options);
  });

  $('.member-group-member-assignment').on('click', function(e) {
    let message_group_data = $($(this)[0]);
    let newOption = new Option(message_group_data.data('message-group-name'), message_group_data.data('message-group-id'), true, true);
    $('#message-group-member-modal #message_group_member_message_group_id').append(newOption).trigger('change');
  });
});
$(function () {
  $('#invoice-checkbox-all').on('click', function(e){
    if(e.target.checked){
      $('.invoice-checkbox').prop('checked', true);
      $('#invoice-mailer').attr('href', '/invoices/send_pdf' + window.location.search)
    } else {
      $('.invoice-checkbox').prop('checked', false);
      $('#invoice-mailer').attr('href', '/invoices/send_pdf')
    }
  })

  $('.invoice-checkbox').on('click', function(e){
    let ids = []
    $('input.invoice-checkbox:checked').map((i, el) => { ids.push($(el).val()) })
    $('#invoice-mailer').attr('href', '/invoices/send_pdf?ids='+ids.join(','))
  })

  // on invoices_update modal show add selected invoices' ids to hidden field
  $('#invoices_update').on('shown.bs.modal', function (event) {
    let invoices_ids = []

    if($('.invoice-checkbox-all:checked').length > 0){
      $('.mass-update').attr('action', '/invoices/mass_update'+window.location.search)
    }else{
      $('.invoice-checkbox:checked').map(function(i, el){
        invoices_ids.push($(el).val());
      });
    }
    $('#invoice_invoice_ids').val(invoices_ids.join(','));
  });
});
$(function () {
  let defaultSelect2Opts = {
    minimumInputLength: 2,
    allowClear: true,
    width: '100%',
    language: lt,
    tags: false,
  };

  function initializeMemberSelects(event, element){
    let member_options = $.extend(defaultSelect2Opts, {
      placeholder: "Įrašykite nario pavadinimą",
      ajax: {
        quietMillis: 300,
        url: "/members.json?skip_create=1",
        dataType: "json",
        data: function(term, page) {
          return {
            q: { name: term.term },
            page_limit: 100,
            page: page
          };
        },
        processResults: function(data) {
          return { results: data.members }
        }
      },
    });

    $(element).find("[rel='members']").each(function () {
      $(this).select2(member_options);
      let member_ids = $(this).data('selected');
      if(member_ids){
        fetchMemberSelections($(this), "/members.json", [member_ids]);
      }
    });
  };

  function fetchMemberSelections(field, url, params){
    if(params.length > 0){
      $.ajax({
        type: 'GET',
        url: url,
        data: {
          q: { members_id_in: params }
        }
      }).then(function (data) {
        data.members.map(function(el, index){
          let option = new Option(el.text, el.id, true, true);
          $(field).append(option).trigger('change');
        });
      });
    }
  }
  
  initializeMemberSelects(null, $(document));
});
$(function () {
  var inputs = $('.write-offs-table input[type=checkbox]');

  inputs.on('change', setWriteOffAmount());

  $('input[name=select_all]').on('change', function () {
    $('#' + this.value).find('input[type=checkbox]').prop('checked', this.checked);
    setWriteOffAmount();
  });

  $('#target_distribution_id').on('change', function () {
    $('.target-distribution').val(this.value);
  });

  setWriteOffAmount();

  function setWriteOffAmount () {
    var amount = inputs.toArray().reduce(function (accumulator, input) {
      var $input = $(input);

      return $input.prop('checked') ? accumulator + parseFloat($input.next().val()) : accumulator;
    }, 0);

    amount += $('#invoices .row').toArray().reduce(function (accumulator, element) {
      return accumulator + parseFloat($(element).data('amount'));
    }, 0);

    $('#write-off-amount').html(toFixed(amount, 2) + ' &euro;');
  }

  // function toFixed (number, precision) {
  //   return +(+(Math.round(+(number + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
  // }

});
$(function () {
  $('.member-select2').select2({
    placeholder: 'Įrašykite pavadinimą',
      minimumInputLength: 2,
      multiple: false,
      width: '100%',
      language: lt,
      ajax: {
        url: "/members.json",
        dataType: 'JSON',
        quietMillis: 300,
        data: function (term) {
          return {
            q: { name: term.term },
          };
        },
        processResults: function(data) {
          return { results: data.members }
        },
        results: function (data) {
          return { results: data.results };
        }
      },
  });

  $('.assign_selfservice').on('click', function(e) {
    $('#member_client_client_id').val($($(this)[0]).data('client-id'))
  });

});
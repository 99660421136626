$(function() {
  // let defaultSelect2Opts = {
  //   allowClear: true,
  //   placeholder: 'Pasirinkite',
  //   width: '100%'
  // };

  // $('.member-group-assignment').on('click', function(e) {
  //   let member_data = $($(this)[0]);
  //   $('#message_group_member_member_id').val(member_data.data('member-id'));
  //   $('#member_id').html(member_data.data('member-name'));
  // });

  let defaultSelect2Opts = {
    tags: false,
    allowClear: true,
    placeholder: 'Pasirinkite',
    width: '100%'
  };

  function initializeOwnershipMemberSelects(event, element){
    let ownership_member_options = $.extend(defaultSelect2Opts, {
      placeholder: "Įrašykite vardą, pavardę",
      minimumInputLength: 2,
      multiple: true,
      language: lt,
      tags: false,
      width: '80%',
      ajax: {
        quietMillis: 300,
        url: "/members/1/sandboxed_members.json",
        dataType: "json",
        data: function(term, page) {
          return {
            q: { name: term.term },
            page_limit: 100,
            page: page
          };
        },
        processResults: function(data) {
          return { results: data }
        }
      },
    });
    $(element).find("[rel='ownership_member_select']").each(function () {
      $(this).select2(ownership_member_options).on('select2:open', function (e) {
        var searchInput = $(this).data('select2').dropdown.$search || $(this).data('select2').selection.$search;
        searchInput[0].focus();
      });
    });
  }


  initializeOwnershipMemberSelects(null, $(document));

  $('thead .member-checkbox').on('click', function(e) {
    if($(this).prop('checked')) {
      $('tbody .member-checkbox').prop('checked', true);
      $('#message-group-modal').modal('show');
      $('#new_q').find('.form-control').each(function() {
        let input = $(this);
        let name = input.attr('name');
        if(name === 'utf8' || name === 'authenticity_token' || name ==='commit') return;
        if($('#message-group-modal form').find(`input[name="${name}"]`).length > 0) return;
        let value = input.val();
        $('#message-group-modal form').append(`<input type="hidden" name="${name}" value="${value}">`);
      });
    }
  });

  $('#message-group-modal').on('hidden.bs.modal', function(e) {
    $('thead .member-checkbox').prop('checked', false);
    $('tbody .member-checkbox').prop('checked', false);
  });


  $('.member-group-assignment').on('click', function(e) {
    let message_group_data = $($(this)[0]);
    let newOption = new Option(message_group_data.data('member-name'), message_group_data.data('member-id'), true, true);
    $('#message-group-member-modal #message_group_member_member_id').append(newOption).trigger('change');
  });
});
$(function() {
  $('.platforms-container').on('cocoon:after-insert', initializeSelects);

  function initializeSelects (event, element) {
    let defaultSelect2Opts = {
      allowClear: true,
      placeholder: 'Pasirinkite',
      width: '100%',
      tags: true
    };

    $(event.target).find("[rel='select2']").each(function() {
      $(this).select2(defaultSelect2Opts);
    });
  }
});

function loadPricingsControls () {

  let url = ''
  if(window.location.href.indexOf('finance') > -1){
    url = '/finance/pricings.json'
  } else {
    url = '/rebroadcast/pricings.json'
  }

  let select_field = $("#q_pricings_id_in",).select2({
    allowClear: true,
    placeholder: "Įrašykite kainodaros pavadinimą",
    minimumInputLength: 2,
    multiple: true,
    width: '100%',
    language: lt,
    ajax: {
      quietMillis: 500,
      url: url,
      dataType: "json",
      data: function(term, page) {
        return {
          q: { name: term.term },
          page: page
        };
      },
      processResults: function(data) {
        return { results: data.pricings.map(function(el, index){
          return { id: el.id, text: el.name }
        }) }
      }
    },
  });

  if(!!$("#q_pricings_id_in").data('selected') && $("#q_pricings_id_in").data('selected').length > 0){
    fetchPricingSelections(select_field, url, $("#q_pricings_id_in").data('selected'))
  }
};

function fetchPricingSelections(field, url, params){
  if(params.length > 0){
    let select_field = $(field);
    $.ajax({
      type: 'GET',
      url: url,
      data: {
        q: { pricings_id_in: params }
      }
    }).then(function (data) {
      data.pricings.map(function(el, index){
        let option = new Option(el.name, el.id, true, true);
        select_field.append(option).trigger('change');
      });
    });
  }
}

$(function() {
  loadPricingsControls();
})

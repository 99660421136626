$(function () {
  // var distributionParent = $('#distribution_parent_id'),
  //     distributionName   = $('#distribution_name'),
  var pathname = window.location.pathname;

  var defaultSelect2Opts = {
    allowClear: true,
    placeholder: 'Pasirinkite',
    width: '100%',
    multiple: false,
    // maximumSelectionLength: 1
  };

  function loadTelevisionPrograms(container){
    var programs = $(container).find('.reserve-program');
    $.map(programs, function(program, index){
      $(program).select2({
        placeholder: 'Įveskite pavadinimą arba ID...',
        minimumInputLength: 1,
        multiple: false,
        // maximumSelectionLength: 1,
        language: lt,
        ajax: {
          delay: 500,
          url: '/television_programs.json',
          dataType: 'json',
          data: function(term, page) {
            return {
              q: {
                id_in_or_name_cont: term.term,
                for_reserve_distribution: pathname.split('/')[2],
              },
              page: page
            };
          },
          processResults: function(data) {
            return { results: data.television_programs }
          },
        },
      });

      $.map($(program).data('selected'), function(selected, index){
        fetchTelevisionProgramSelections(program, "/television_programs.json", selected);
      });
  
      $(program).on('change', function () {
        var $this = $(this);
        var fields = $this.closest('.nested-fields').find('.reserve-ownership-member');

        $.map(fields, function(field, index){
          var ownership_member_field = field;
          $(ownership_member_field).empty();
          var television_program_id = $this.val();
          if(television_program_id){
            $.getJSON('/ownership_members', {
              q: {
                programs: television_program_id,
                distribution: pathname.split('/')[2],
              }
            }, function(data){
              var ownership_member_selection = $(ownership_member_field).data('selected')[0];
              $(ownership_member_field).select2(defaultSelect2Opts);

              data.map(function(el, index){
                var formatted_text = el.member_name + ' ' + el.percentage + '%';
                var option = new Option(formatted_text, el.id, false, el.id == ownership_member_selection);
                $(ownership_member_field).append(option);
              });
              $(ownership_member_field).trigger('change');
            });
          }else{
            $(ownership_member_field).select2(defaultSelect2Opts);
          }
        });
      });
    });

  }

  function fetchTelevisionProgramSelections(field, url, selected){
    var select_field = $(field);
    $.ajax({
      type: 'GET',
      url: url,
      data: {
        q: { id_in: selected }
      }
    }).then(function (data) {
      data.television_programs.map(function(el, index){
        var option = new Option(el.text, el.id, false, el.id == selected);
        select_field.append(option)
      });
      select_field.trigger('change');
    });
  }

  // if (pathname.indexOf('new') > -1) distributionName.val(distributionParent.find('option:selected').text());
  // distributionParent.change(function () {
  //   distributionName.val($(this).find('option:selected').text());
  // });

  $('.bucket-percentage').on('change', function (event) {
    var $this = $(this);

    $('.bucket-percentage').filter('input[data-type=' + $this.data('type') + ']').not($this).val($this.val());
  });

  $('.television-programs-container').on('cocoon:after-insert', function (e, item) {
    loadTelevisionPrograms(item);
  });

  var containers = $('.television-programs-container');
  $.map(containers, function(container, index){
    loadTelevisionPrograms(container);
  });
});